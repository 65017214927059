
import { defineComponent, ref, watch, nextTick, PropType } from 'vue';

export default defineComponent({
  name: 'DiscussTagGroup',
  props: {
    tags: {
      type: Array as PropType<string[]>,
      default: () => ([]),
    },
  },
  setup(props) {
    const discussTagGroup = ref<HTMLElement | null>(null);
    const plusBtn = ref<HTMLElement | null>(null);
    const showIndex = ref<number>(0);
    const isFold = ref<boolean>(true);
    // 动态计算评论标签是否需要收起
    watch([props.tags, isFold], (newVal, oldVal) => {
      if (newVal.length && isFold.value) {
        nextTick(() => {
          showIndex.value = 0;
          const tagUl = discussTagGroup.value as HTMLElement;
          const tagUlWidth = tagUl?.clientWidth;
          const tagLi = tagUl?.children || [];
          let total = 0;
          Array.prototype.slice.call(tagLi).forEach((li, idx) => {
            total += li.clientWidth;
            if (total >= tagUlWidth) {
              if (!showIndex.value) showIndex.value = idx;
              li.style.display = 'none';
            }
          });
        });
      }
    }, {
      immediate: true,
    });
    // 点击+xx按钮展示所有tags
    const showAllTags = () => {
      const tagUl = discussTagGroup.value as HTMLElement;
      const tagLi = tagUl?.children || [];
      isFold.value = !isFold.value;
      Array.prototype.slice.call(tagLi).forEach((li) => {
        li.style.display = 'block';
      });
    };
    return {
      plusBtn,
      discussTagGroup,
      showIndex,
      isFold,
      showAllTags,
    };
  },
});
