
import { defineComponent, PropType, ref, reactive, toRefs } from 'vue';
import DiscussTagGroup from '@/modules/miaokuan-manage/common/components/discuss-tag-group/index.vue';
import { SUB_STATUS_ENUMS } from '@/modules/miaokuan-manage/common/constant';
import { IGetDomesticGoodsInfoRes } from '@/modules/resource-manage/domestic-goods/api/type.d';
import { SALESTATUS } from '@/modules/miaokuan-manage/common/components/shopDetail/constant';
import { useMathFormat } from '@/modules/miaokuan-manage/common/composables/use-math-format';
import dayjs from 'dayjs';

export default defineComponent({
  emits: ['refresh'],
  components: { DiscussTagGroup },
  props: {
    data: {
      type: Object as PropType<IGetDomesticGoodsInfoRes>,
      default: () => ({}),
    },
  },
  setup(props) {
    const { intValidator } = useMathFormat();
    const baseLabels = [{
      label: '店铺',
      attr: 'storeName',
    }, {
      label: 'SPU',
      attr: 'spu',
    }, {
      label: '商品状态',
      attr: 'spuStatus',
    }, {
      label: '价格',
      attr: 'price',
    }, {
      label: '总评论数',
      attr: 'commentNum',
    },
    {
      label: '好评度',
      attr: '',
    },
    {
      label: '上架日期',
      attr: 'latelyShelfDate',
    }, {
      label: '下架日期',
      attr: 'latelyOffDate',
    }];

    const info = reactive({
      base: ref<Array<{label: string; attr: string; value: string;}>>([]),
      shop: ref<Array<{label: string; value: string;}>>([]),
      description: ref<string>(props.data.description),
      score: ref<string>(props.data.description),
      tags: ref<string[]>([]),
      counts: ref<string>(props.data.commentNum),
    });
    baseLabels.forEach((item) => {
      let value = item.attr ? (props.data as any)[item.attr] : '';
      if (item.label === '商品状态') {
        const name = `STATUS${value}` as ('STATUS1' | 'STATUS2' | 'STATUS3' | 'STATUS4');
        value = SALESTATUS[name];
      }
      if (item.label === '上架日期' || item.label === '下架日期') {
        value = value ? dayjs(value).format('YYYY年MM月DD日') : '';
      }
      info.base.push({ ...item, value });
    });
    const shopData = props.data.productParameters ? JSON.parse(props.data.productParameters) : [];
    info.shop = Object.keys(shopData).map((key) => {
      return {
        label: key,
        value: shopData[key],
      };
    });
    info.tags = props.data.commentTag ? JSON.parse(props.data.commentTag) : [];

    return {
      ...toRefs(info),
      dayjs,
      intValidator,
      SUB_STATUS_ENUMS,
    };
  },
});
