/*
 * @Author: sharon
 * @Date: 2022-01-14 19:15:59
 * @LastEditTime: 2022-01-17 18:16:15
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /miao-kuan/src/modules/miaokuan-manage/components/shopDetail/constant/index.ts
 */
export enum SALESTATUS {
  STATUS1 = '在售',
  STATUS2 = '下架',
  STATUS3 = '预售',
  STATUS4 = '售罄',
}

export enum ONLINE_TYPE {
  FAST = 'fast',
  SLOW = 'slow'
}

export enum COMMODITY_STATUS_ENUM {
  ON_THE_SHELF = 1,
  REMOVED = 2,
}
