import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34edbfd8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "tags",
  ref: "discussTagGroup"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "discussTagGroupBox",
    style: _normalizeStyle({'flex-wrap': _ctx.isFold ? 'nowrap' : 'wrap'})
  }, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (item) => {
        return (_openBlock(), _createElementBlock("li", { key: item }, [
          _createElementVNode("span", null, _toDisplayString(item), 1)
        ]))
      }), 128))
    ], 512),
    _withDirectives(_createElementVNode("div", {
      ref: "plusBtn",
      class: "plusBtn",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showAllTags && _ctx.showAllTags(...args)))
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.isFold ? `+${_ctx.tags.length - _ctx.showIndex}...` : '收起'), 1)
    ], 512), [
      [_vShow, _ctx.showIndex > 0]
    ])
  ], 4))
}